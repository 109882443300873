export const paymentTypes = [
  { key: "WALLET_ADJUSTMENT", value: "Wallet Adjustment" },
  { key: "EXTERNAL", value: "External Payment" },
  { key: "INTERNAL", value: "Internal Payment" },
  { key: "BALANCE_TOP_UP", value: "Balance Topup" },
];

export const paymentStatusMessage = {
  ACTIVE: "Initiated",
  PAID: "Success",
  EXPIRED: "Failed",
  CANCELLED: "Failed",
  SUCCESS: "Success",
  ERROR: "Failed",
  COMPLETED: "Success",
};

export enum PaymentStatus {
  ACTIVE = "ACTIVE",
  PAID = "PAID",
  EXPIRED = "EXPIRED",
  CANCELLED = "CANCELLED",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  COMPLETED = "COMPLETED",
}
