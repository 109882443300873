import React, { useEffect } from "react";
import Styles from "../../Shared/sharedStyles.module.scss";
import InputField from "../../Shared/inputField/inputField";
import ErrorMessage from "../../../../../Shared/error-message/errorMessage";
import Button from "../../../../../Shared/button/button";
import {
  primaryButtonHoverStylePopup,
  primaryButtonStylePopup,
} from "../../../../../Shared/buttonStyles";
import { MEDIUM } from "../../../../../Shared/buttonSize";
import {
  isNumberOnly,
  validVehicleRegNumber,
} from "../../../../../utils/validation";

function RcValuationPopup({ toastTimeout, handleResponse, name }: any) {
  const errorObject = {
    vehicleRegNumberError: false,
    kilometerError: false,
    consentError: false,
  };

  const vehicleRegNumberRef = React.useRef<any>("");
  const kilometerRef = React.useRef<any>("");

  const [error, setError] = React.useState({ ...errorObject });
  const [isChecked, setIsChecked] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  // checking vehicle reg number valid or not on change
  function checkVehicleRegistrationNumber() {
    if (
      !validVehicleRegNumber(
        vehicleRegNumberRef.current.value.trim().toUpperCase()
      )
    ) {
      setError({ ...error, vehicleRegNumberError: true });
    } else {
      setError({ ...error, vehicleRegNumberError: false });
    }
  }

  // checking kilometer valid or not on change
  function checkKilometer() {
    const total_distance_driven = kilometerRef.current.value
      .trim()
      .toUpperCase();
    if (total_distance_driven === "" || !isNumberOnly(total_distance_driven)) {
      setError({ ...error, kilometerError: true });
    } else {
      setError({ ...error, kilometerError: false });
    }
  }

  // conditions to check Run verfication button should be enabled or disabled
  useEffect(() => {
    try {
      if (
        vehicleRegNumberRef.current.value.trim() === "" ||
        error.vehicleRegNumberError ||
        kilometerRef.current.value.trim() === "" ||
        error.kilometerError
      ) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    } catch (err) {
      setIsDisabled(true);
      toastTimeout(true, "error", (err as Error).message);
    }
  }, [error]);

  function handleCheck() {
    const vehicle_registration_number = vehicleRegNumberRef.current.value.toUpperCase();
    const total_distance_driven = kilometerRef.current.value;
    const consent = isChecked === true ? "Y" : "N";

    if (
      vehicle_registration_number === "" ||
      !validVehicleRegNumber(vehicle_registration_number)
    ) {
      setError(() => ({
        ...error,
        vehicleRegNumberError: true,
      }));
      return false;
    }
    if (total_distance_driven === "" || !isNumberOnly(total_distance_driven)) {
      setError(() => ({
        ...error,
        total_distance_driven: true,
      }));
      return false;
    }
    if (!isChecked) {
      setError(() => ({
        ...error,
        consentError: true,
      }));
      return false;
    }
    setLoading(true);
    handleResponse(
      { vehicle_registration_number, total_distance_driven, consent },
      setLoading
    );
  }
  return (
    <div className={`${Styles.content}`}>
      <div className={`${Styles.trialText} pl-2`}>Runnning Trial on</div>
      <div className={`${Styles.headingText} pl-2`}>{name} </div>
      <div className={`${Styles.infoForm} pl-2`}>
        <hr />
        <p className="mt-4">Fill in the below details to run verification</p>
        <div className="d-flex flex-column">
          <div className="d-flex flex-column mb-4 ">
            <label className={`${Styles.label} flex-grow-1`}>
              Vehicle Registration Number
            </label>
            <InputField
              id="id-input-rc-number"
              onChange={() => {
                checkVehicleRegistrationNumber();
              }}
              maxLength={10}
              inputRef={vehicleRegNumberRef}
              isError={error.vehicleRegNumberError}
              purpose="forRc"
            />
            {error.vehicleRegNumberError && (
              <ErrorMessage>
                Registration number field is not valid
              </ErrorMessage>
            )}
          </div>
          <div>
            <div className="d-flex flex-column mb-4 ">
              <label className={`${Styles.label} flex-grow-1`}>
                total_distance_driven
              </label>
              <InputField
                id="id-input-kilometer"
                onChange={() => {
                  checkKilometer();
                }}
                maxLength={7}
                inputRef={kilometerRef}
                isError={error.kilometerError}
                purpose="forRc"
              />
              {error.kilometerError && (
                <ErrorMessage>
                  total_distance_driven field is not valid
                </ErrorMessage>
              )}
            </div>
          </div>
        </div>
        <div className="pt-4 pl-4 pb-4 mt-4 d-flex flex-column">
          <div>
            <input
              type="checkbox"
              className="form-check-input"
              onChange={(e: any) => setIsChecked(e.target.checked)}
            />
            <p className={`${Styles.ackText}`}>
              I hearby agree, to let zoop.one verify my data for verification
            </p>
          </div>
          {error.consentError && (
            <ErrorMessage>Please provide consent!</ErrorMessage>
          )}
        </div>
        <div className={`${Styles.buttonGroup}`}>
          <Button
            id="id-submit-button-rc"
            hoveredStyle={primaryButtonHoverStylePopup}
            disabled={isDisabled}
            size={MEDIUM}
            isLoading={loading}
            style={primaryButtonStylePopup}
            onClick={() => {
              setTimeout(() => setError({ ...errorObject }), 2000);
              handleCheck();
            }}
          >
            Run Verification
          </Button>
        </div>
      </div>
    </div>
  );
}

export default RcValuationPopup;
